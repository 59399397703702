<template>
    <div>
        <!-- Contact Page -->
        <section class="contact-page">
            <div class="container">
                <div class="title">
                    <h4>{{ $t('Contact.title') }}</h4>
                    <p>
                        {{ $t('Contact.subtitle') }}
                    </p>
                </div>

                <div class="form-section mx-2">
                    <div class="row justify-content-center">
                        <div class="col-lg-5 pr-0">
                            <div class="info">
                                <div class="info-title">
                                    <h4>
                                        {{
                                            $t(
                                                'Contact.contactInformation.title'
                                            )
                                        }}
                                    </h4>
                                    <p>
                                        {{
                                            $t(
                                                'Contact.contactInformation.subtitle.firstLine'
                                            )
                                        }}<br />
                                        {{
                                            $t(
                                                'Contact.contactInformation.subtitle.secondLine'
                                            )
                                        }}
                                    </p>
                                </div>

                                <ul class="links">
                                    <!-- <li>
                                        <i class="fa fa-phone"></i>
                                        <a href="tel:+0123 4567 8910">
                                            +0123 4567 8910
                                        </a>
                                    </li> -->
                                    <li>
                                        <i class="fa fa-envelope"></i>
                                        <a href="mailto:info@duplio.se">
                                            info@duplio.se
                                        </a>
                                    </li>
                                    <li>
                                        <i class="fa fa-map-marker"></i>
                                        Mässans gata 10, Göteborg
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-7 pl-0">
                            <div class="right">
                                <ValidationObserver ref="form">
                                    <form
                                        class="d-flex flex-column"
                                        style="gap: 2rem"
                                    >
                                        <span
                                            v-for="(data, index) in dataForm"
                                            :key="index"
                                        >
                                            <label v-text="$t(data.label)" />
                                            <ValidationProvider
                                                v-if="data.type == 'email'"
                                                #default="{errors}"
                                                :name="$t(data.label)"
                                                :rules="data.rules"
                                            >
                                                <input
                                                    type="text"
                                                    v-model="data.value"
                                                    :placeholder="
                                                        $t(data.label)
                                                    "
                                                />
                                                <small class="text-danger">
                                                    {{ errors[0] }}
                                                </small>
                                            </ValidationProvider>

                                            <ValidationProvider
                                                v-else-if="
                                                    data.type == 'message'
                                                "
                                                #default="{errors}"
                                                :name="$t(data.label)"
                                                :rules="data.rules"
                                            >
                                                <textarea
                                                    id="message"
                                                    v-model="data.value"
                                                    :placeholder="
                                                        $t(data.label)
                                                    "
                                                />

                                                <small class="text-danger">
                                                    {{ errors[0] }}
                                                </small>
                                            </ValidationProvider>

                                            <ValidationProvider
                                                v-else
                                                #default="{errors}"
                                                :name="$t(data.label)"
                                                :rules="data.rules"
                                            >
                                                <input
                                                    type="text"
                                                    v-model="data.value"
                                                    :placeholder="
                                                        $t(data.label)
                                                    "
                                                />
                                                <small class="text-danger">
                                                    {{ errors[0] }}
                                                </small>
                                            </ValidationProvider>
                                        </span>
                                    </form>
                                </ValidationObserver>

                                <button @click="submit" class="button">
                                    {{
                                        $t(
                                            'Contact.contactInformation.form.button'
                                        )
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Contact Page End -->
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {required, email, integer} from '@validations';
import axios from 'axios';
export default {
    name: 'Contact',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            required,
            email,
            config: useAppConfig(),
            integer,
            dataForm: [
                {
                    label: 'Contact.contactInformation.form.name.label',
                    type: 'input',
                    params: 'full_name',
                    rules: 'required',
                    value: '',
                },
                {
                    label: 'Contact.contactInformation.form.email.label',
                    type: 'email',
                    params: 'email',
                    rules: 'required|email',
                    value: '',
                },
                {
                    label: 'Contact.contactInformation.form.number.label',
                    type: 'input',
                    rules: 'integer|required',
                    params: 'phone_number',
                    value: '',
                },
                {
                    label: 'Contact.contactInformation.form.message.label',
                    type: 'message',
                    params: 'message',
                    rules: 'required',
                    value: '',
                },
            ],
        };
    },

    computed: {
        messageLang() {
            if (this.getLang == '') {
                return localStorage.getItem('mainLang') == 'sv'
                    ? 'message'
                    : 'eng_message';
            } else
                return localStorage.getItem('mainLang') == 'sv'
                    ? 'message'
                    : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        submit() {
            let obj = {};

            this.dataForm.forEach(element => {
                obj[element.params] = element.value;
            });

            var newAxios = axios.create({
                baseURL: `${process.env.VUE_APP_SERVICE_URL}/api/v1`,
            });

            this.$refs.form.validate().then(res => {
                if (res) {
                    newAxios.post('/contact_form/', obj).then(res => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t('Message.Success'),
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: res.data['message'],
                            },
                        });

                        this.dataForm.forEach(element => {
                            element.value = '';
                        });

                        this.$refs.form.reset();
                    });
                }
            });
        },
    },

    mounted() {
        //Validation language
        this.locale = localStorage.getItem('mainLang');
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/contact.scss';
</style>
